<template>
  <section
    class="category-item-container"
    v-if="productListItem.productItem != undefined"
  >
    <v-container>
      <v-row :style="{ backgroundColor: colors[currentColor] }">
        <v-col>
          <div class="category-item-title-container">
            <h2>{{ productListItem.name }}</h2>
          </div>
        </v-col>
        <v-col class="justify-end d-flex more-link"
          ><router-link
            :to="
              `/store.linerp.co/product-lists/${providerId}/${productListItem.id}`
            "
          >
            查看更多<i class="ri-arrow-right-s-line"></i></router-link
        ></v-col>
      </v-row>
    </v-container>
    <v-container
      :style="{ backgroundColor: colorsOp[currentColor], padding: '20px 30px' }"
    >
      <v-row>
        <v-col
          class="li-list"
          v-for="item in productListItem.productItem.slice(0, 4)"
          :key="item.id"
          cols="12"
          sm="3"
        >
          <router-link :to="`/provider-store/${providerId}/product/${item.id}`">
            <div class="category-item-wrap">
              <v-img
                v-if="item.photos[0]"
                width="100%"
                aspect-ratio="1"
                cover
                :src="item.photos[0].size_list.origin.url"
                class="category-item-wrap-img"
              >
              </v-img>
              <v-img
                v-else
                src="~@/assets/product-dafault.jpeg"
                width="100%"
                aspect-ratio="1"
                cover
                class="category-item-wrap-img"
              ></v-img>
              <h4>{{ item.name }}</h4>
            </div>
          </router-link>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  name: "CategoryItem",
  components: {},
  data() {
    return {
      colors: [
        "rgb(199, 62, 58)",
        "rgb(62, 156, 234)",
        "rgb(75, 173, 148)",
        "rgb(228, 102, 39)",
      ], // 顏色的順序在這裡定義
      colorsOp: [
        "rgb(199, 62, 58, 0.1)",
        "rgb(62, 156, 234, 0.1)",
        "rgb(75, 173, 148, 0.1)",
        "rgb(228, 102, 39, 0.1)",
      ],
    };
  },
  props: {
    productListItem: {
      type: Object,
      default: () => {},
    },
    index: {
      type: Number,
    },
    providerId: {
      type: String,
    },
  },
  created() {
    // console.log("productListItem", this.productListItem);
  },
  computed: {
    currentColor() {
      return this.index % this.colors.length;
    },
  },
  methods: {},
};
</script>

<style>
.category-item-container {
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 20px;
}
.category-item-container .ri-arrow-right-s-line {
  font-size: 18px;
}
.category-item-container .more-link a {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.category-item-title-container h2 {
  font-size: 20px;
  color: white;
  font-weight: 600;
}
.category-item-wrap {
  background-color: white;
  padding: 13px;
}
.category-item-wrap h4 {
  color: black;
  font-weight: 500;
  text-align: center;
}
.category-item-wrap-img {
  margin-bottom: 10px;
}
.li-list {
  transition: transform 0.3s ease-in-out;
}

.li-list:hover {
  transform: scale(1.1);
}
</style>
